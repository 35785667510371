<template>
  <HomePage />
</template>

<script>
import HomePage from "./components/pages/HomePage.vue";

export default {
  name: "App",
  components: {
    HomePage,
  },
};
</script>

<style>
/* ファイルパスに従って@importntの後にファイルパスを書き込む */
@import "/scss/app.css";
</style>