<template>
  <v-container>
    <!-- PC -->
    <v-form ref="form" v-model="contactFormValidation.valid" lazy-validation>
      <div class="hidden-sm-and-down charColor--text">
        <div class="text-center d-flex justify-center">
          <TitleWithSubTitle
            title="CONTACT"
            sub="お問い合わせ"
            class="mt-13 mb-13"
          />
        </div>
        <!-- PC:お問い合わせ種別 -->
        <div class="d-flex justify-center">
          <v-col cols="3">
            お問い合わせ種別<span class="red--text"> *</span>
            <br />
            <span class="english-style">Purpose of Inquiry</span>
          </v-col>
          <v-col cols="4" class="ma-0 pa-0">
            <v-select
              v-model="contactForm.purpose"
              :items="contactForm.item"
              :rules="contactFormValidation.purposeRules"
              label="選択してください"
              single-line
              outlined
              item-color="#808077"
              required
              solo
              class="max-width-style"
            ></v-select>
          </v-col>
        </div>
        <!-- PC:会社名 -->
        <div class="d-flex justify-center">
          <v-col cols="3">
            会社名 <br />
            <span class="english-style">Company Name</span>
          </v-col>
          <v-col cols="4" class="ma-0 pa-0">
            <v-text-field
              v-model="contactForm.company"
              single-line
              outlined
              required
              solo
              class="max-width-style"
            ></v-text-field>
          </v-col>
        </div>
        <!-- PC:名前 -->
        <div class="d-flex justify-center">
          <v-col cols="3">
            名前<span class="red--text"> *</span>
            <br />
            <span class="english-style">Your Name</span>
          </v-col>
          <v-col cols="4" class="ma-0 pa-0">
            <v-text-field
              v-model="contactForm.name"
              :rules="contactFormValidation.nameRules"
              single-line
              outlined
              required
              solo
              class="max-width-style"
            ></v-text-field>
          </v-col>
        </div>
        <!-- PC:メールアドレス -->
        <div class="d-flex justify-center">
          <v-col cols="3">
            メールアドレス<span class="red--text"> *</span><br />
            <span class="english-style">Email</span>
          </v-col>
          <v-col cols="4" class="ma-0 pa-0">
            <v-text-field
              v-model="contactForm.email"
              :rules="contactFormValidation.emailRules"
              single-line
              outlined
              required
              solo
              class="max-width-style"
            ></v-text-field>
          </v-col>
        </div>
        <!-- PC:電話番号 -->
        <div class="d-flex justify-center">
          <v-col cols="3">
            電話番号 <br />
            <span class="english-style">Phone Number</span>
          </v-col>
          <v-col cols="4" class="ma-0 pa-0">
            <v-text-field
              v-model="contactForm.telephone"
              single-line
              outlined
              required
              solo
              class="max-width-style"
            ></v-text-field>
          </v-col>
        </div>
        <!-- PC:お問い合わせ内容 -->
        <div class="d-flex justify-center mb-0">
          <v-col cols="3">
            お問い合わせ内容<span class="red--text"> *</span><br />
            <span class="english-style">Message</span>
          </v-col>
          <v-col cols="4" class="ma-0 pa-0">
            <v-textarea
              v-model="contactForm.contents"
              :rules="contactFormValidation.contentsRules"
              single-line
              outlined
              no-resize
              rows="10"
              required
              solo
              class="max-width-style"
            ></v-textarea>
          </v-col>
        </div>
        <!-- PC:問い合わせボタン -->
        <div class="d-flex justify-center">
          <v-col cols="3"> </v-col>
          <v-col cols="4" class="ma-0 pa-0">
            <v-card class="d-flex space-between-style" outlined tile>
              <v-btn
                :loading="contactForm.loading"
                :disabled="!contactFormValidation.valid"
                class="
                  max-width-style
                  flex-grow-1
                  rounded-pill
                  accent
                  grey--text
                  mb-5
                "
                @click="sendMail()"
              >
                問い合わせる
              </v-btn>
              <v-snackbar
                v-model="snackBar.show"
                :color="snackBar.color"
                bottom
                right
                :timeout="6000"
                class="font-weight-bold"
              >
                {{ snackBar.message }}
              </v-snackbar>
            </v-card>
          </v-col>
        </div>
      </div>

      <!-- SP -->
      <div class="hidden-md-and-up charColor--text">
        <v-row class="text-center">
          <v-col>
            <TitleWithSubTitle
              title="CONTACT"
              sub="お問い合わせ"
              class="mt-13 mb-13"
            />
          </v-col>
        </v-row>
        <!-- SP:お問い合わせ種別 -->
        <div>
          お問い合わせ種別<span class="red--text"> *</span>
          <br />
          <span class="english-style">Purpose of Inquiry</span>
          <v-select
            v-model="contactForm.purpose"
            :items="contactForm.item"
            :rules="contactFormValidation.purposeRules"
            label="選択してください"
            single-line
            outlined
            required
            solo
            item-color="#808077"
          ></v-select>
        </div>
        <!-- SP:会社名 -->
        <div>
          会社名 <br />
          <span class="english-style">Company Name</span>
          <v-text-field
            v-model="contactForm.company"
            single-line
            outlined
            required
            solo
          ></v-text-field>
        </div>
        <!-- SP:名前 -->
        <div>
          名前<span class="red--text"> *</span><br />
          <span class="english-style">Your Name</span>
          <v-text-field
            v-model="contactForm.name"
            :rules="contactFormValidation.nameRules"
            single-line
            outlined
            required
            solo
          ></v-text-field>
        </div>
        <!-- SP:メールアドレス -->
        <div>
          メールアドレス<span class="red--text"> *</span><br />
          <span class="english-style">Email</span>
          <v-text-field
            v-model="contactForm.email"
            :rules="contactFormValidation.emailRules"
            single-line
            outlined
            required
            solo
          ></v-text-field>
        </div>
        <!-- SP:電話番号 -->
        <div>
          電話番号 <br />
          <span class="english-style">Phone Number</span>
          <v-text-field
            v-model="contactForm.telephone"
            single-line
            outlined
            required
            solo
          ></v-text-field>
        </div>
        <!-- SP:お問い合わせ内容 -->
        <div>
          お問い合わせ内容<span class="red--text"> *</span><br />
          <span class="english-style">Message</span>
          <v-textarea
            v-model="contactForm.contents"
            :rules="contactFormValidation.contentsRules"
            single-line
            outlined
            no-resize
            rows="10"
            required
            solo
          ></v-textarea>
        </div>
        <!-- SP:問い合わせるボタン -->
        <v-card class="d-flex space-between-style" outlined tile>
          <v-btn
            :loading="contactForm.loading"
            :disabled="!contactFormValidation.valid"
            class="flex-grow-1 rounded-pill accent grey--text mb-5"
            @click="sendMail()"
          >
            問い合わせる
          </v-btn>
          <v-snackbar
            v-model="snackBar.show"
            :color="snackBar.color"
            bottom
            right
            :timeout="6000"
            class="font-weight-bold"
          >
            {{ snackBar.message }}
          </v-snackbar>
        </v-card>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import { functions } from "@/plugins/firebase";
import TitleWithSubTitle from "../molecules/TitleWithSubTitle.vue";

export default {
  name: "Contact",
  components: {
    TitleWithSubTitle,
  },
  data: () => ({
    contactForm: {
      purpose: "",
      item: ["発注", "相談", "その他"],
      company: "",
      name: "",
      email: "",
      telephone: "",
      contents: "",
      loading: false,
    },
    contactFormValidation: {
      valid: false,
      purposeRules: [(v) => !!v || "お問い合わせ種別は必須項目です"],
      nameRules: [(v) => !!v || "名前は必須項目です"],
      emailRules: [
        (v) => !!v || "メールアドレスは必須項目です",
        (v) => /.+@.+/.test(v) || "正しいメールアドレスを入力してください",
      ],
      contentsRules: [(v) => !!v || "内容は必須項目です"],
    },
    snackBar: {
      show: false,
      color: "",
      message: "",
    },
  }),

  methods: {
    sendMail: function () {
      if (this.$refs.form.validate()) {
        this.contactForm.loading = true;
        const mailer = functions.httpsCallable("sendMail");

        mailer(this.contactForm)
          .then(() => {
            this.formReset();
            this.showSnackBar("success", "送信完了しました");
          })
          .catch((err) => {
            this.showSnackBar("error", "送信に失敗しました。");
            console.log(err);
          })
          .finally(() => {
            this.contactForm.loading = false;
          });
      }
    },
    showSnackBar: function (color, message) {
      this.snackBar.message = message;
      this.snackBar.color = color;
      this.snackBar.show = true;
    },
    formReset: function () {
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped>
.space-between-style {
  justify-content: space-between;
  border: none;
}

.english-style {
  font-size: 12.8px;
}

.max-width-style {
  min-width: 400px !important;
}

.button-width-style {
  max-width: 80%;
}

.v-application .primary--text {
  color: black !important;
  caret-color: black !important;
}
</style>
