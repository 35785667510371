<template>
  <!-- max-width: 900px; -->
  <v-container>
    <v-row class="text-center">
      <v-col>
        <!-- PC版 -->
        <TitleWithSubTitle
          title="PHILOSOPHY"
          sub="企業理念"
          class="hidden-sm-and-down mt-10 mb-14"
        />
        <!-- SP版 -->
        <TitleWithSubTitle
          title="PHILOSOPHY"
          sub="企業理念"
          class="hidden-md-and-up mt-10 mb-8"
        />
        <!-- テキストサイズ、色、フレックス（横中央、縦上）、下マージン10 -->
        <div
          class="
            text-h5
            charColor--text
            d-flex
            justify-center
            align-start
            mb-14
          "
        >
          <!-- 左画像 -->
          <img class="mr-6" src="../../assets/left-quote.svg" width="22px" />
          <span class="line-height-style">
            持続可能な健康社会の実現を目指しています
          </span>
          <!-- 右画像 -->
          <img class="ml-6" src="../../assets/right-quote.svg" width="22px" />
        </div>
        <!-- PC版 -->
        <div class="hidden-sm-and-down border-pc-style ma-auto mb-16" />
        <p class="hidden-sm-and-down text-h5 charColor--text mb-12">
          地域資源を活用した新しい健康経営を想像するための4つのミッション
        </p>
        <!-- SP版 -->
        <div class="hidden-md-and-up border-sp-style ma-auto mb-14" />
        <div class="mb-4">
          <p class="hidden-md-and-up text-h6 charColor--text mb-12">
            地域資源を活用した<br />新しい健康経営を想像するための<br />4つのミッション
          </p>
        </div>
        <MissionItems />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TitleWithSubTitle from "../molecules/TitleWithSubTitle.vue";
import MissionItems from "../molecules/MissionItems.vue";
export default {
  name: "Philosophy",
  components: {
    TitleWithSubTitle,
    MissionItems,
  },
  data: () => ({}),
};
</script>

<style scoped>
.border-pc-style {
  border-top: 1px solid #dcdcd2;
  width: 50%;
}

.border-sp-style {
  border-top: 1px solid #dcdcd2;
  width: 70%;
}

.line-height-style {
  line-height: 1.7;
}
</style>
