<template>
  <div class="charColor--text text-body-2 mb-6">
    <div class="hidden-sm-and-down font-style">
      これからの社会は予測不能なVUCAの時代とも言われています。VUCAとは、Volatility（変動性）、Uncertainty（不確実性）、Complexity（複雑性）、Ambiguity（曖昧性）の4つの単語の頭文字をとった造語で、取り巻く社会環境が複雑になることで次々と想定外の出来事が起こり、将来予測が困難な状況を意味します。<br /><br />
      医療・健康においても、単に病気は個人の問題ではなく、コミュニティ・社会・環境など様々な因子が複雑に関係しあい、病気が生まれると考えられるようになっています。その意味で、今後は医療・健康も個人の努力だけでは解決できない多くの問題を含んでおり、未来予測が困難な状態です。そのため、これからの医療・健康を考えるには、個人の問題だけでなく、その人が住む地域やコミュニティ、さらには社会や環境にまで配慮した総合的な健康社会の確立が必要不可欠なのです。<br /><br />
      そこで、私たちは明治国際医療大学で長年培ってきた東洋医学に関する知見を応用して、ユーザーと鍼灸・整骨院などの治療院の連携を密にするプラットフォームを開発することで、市民の健康になる力を根底からサポートすると共に、健康になる力を地域の活動や資源と結びつけることで地域活性化につなげ、地域に健康コミュニティを形成するお手伝いを、ビジネスを通じて行っています。さらに、個々や地域の健康になる力を少子高齢化問題や環境問題などの様々な社会問題と結びつけることで、住みやすい社会を実現するための力に変えることを目指しています。<br /><br />
      そのために、私たちは社会が必要とする様々なプラットフォームを提供することで、安心して暮らせる持続可能な健康社会の実現を目指し、日々チャレンジしていきます。
    </div>
    <div class="hidden-md-and-up font-style ma-3">
      これからの社会は予測不能なVUCAの時代とも言われています。VUCAとは、Volatility（変動性）、Uncertainty（不確実性）、Complexity（複雑性）、Ambiguity（曖昧性）の4つの単語の頭文字をとった造語で、取り巻く社会環境が複雑になることで次々と想定外の出来事が起こり、将来予測が困難な状況を意味します。<br /><br />
      医療・健康においても、単に病気は個人の問題ではなく、コミュニティ・社会・環境など様々な因子が複雑に関係しあい、病気が生まれると考えられるようになっています。その意味で、今後は医療・健康も個人の努力だけでは解決できない多くの問題を含んでおり、未来予測が困難な状態です。そのため、これからの医療・健康を考えるには、個人の問題だけでなく、その人が住む地域やコミュニティ、さらには社会や環境にまで配慮した総合的な健康社会の確立が必要不可欠なのです。<br /><br />
      そこで、私たちは明治国際医療大学で長年培ってきた東洋医学に関する知見を応用して、ユーザーと鍼灸・整骨院などの治療院の連携を密にするプラットフォームを開発することで、市民の健康になる力を根底からサポートすると共に、健康になる力を地域の活動や資源と結びつけることで地域活性化につなげ、地域に健康コミュニティを形成するお手伝いを、ビジネスを通じて行っています。さらに、個々や地域の健康になる力を少子高齢化問題や環境問題などの様々な社会問題と結びつけることで、住みやすい社会を実現するための力に変えることを目指しています。<br /><br />
      そのために、私たちは社会が必要とする様々なプラットフォームを提供することで、安心して暮らせる持続可能な健康社会の実現を目指し、日々チャレンジしていきます。
    </div>
  </div>
</template>

<script>
export default {
  name: "Greeting",
};
</script>

<style scoped>
.font-style {
  font-size: 14px;
  line-height: 1.7;
}
</style>
