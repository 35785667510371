<template>
  <!-- 共通ロゴボタン -->
  <div class="d-flex flex-column">
    <v-btn text height="70px" @click="toTop">
      <v-img
        alt="YOJYOnet-logo"
        class="mt-1"
        width="180"
        height="60"
        src="../../assets/logo-04.svg"
      />
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "LogoButton",
  methods: {
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>

<style scoped></style>