<template>
  <div>
    <!-- PC版 -->
    <div class="hidden-sm-and-down">
      <v-footer padless class="secondary">
        <v-card height="88px" class="secondary d-flex align-center" flat tile>
          <v-card-text class="footer-font-style text-left">
            <p class="white--text mb-0 ml-9 d-flex align-center">
              ©2021 YOJYOnet.Co.,Ltd.
            </p>
          </v-card-text>
        </v-card>
        <v-spacer></v-spacer>
        <v-card height="88px" flat tile class="secondary d-flex align-center">
          <v-card-text
            class="
              footer-font-style
              right-border-style
              white--text
              text-left
              pt-0
              pb-0
            "
          >
            <a :href="ghc_url"
              >Good Health Communicationsの情報発信サイト<br />
              good-health-comms.jp</a
            >
          </v-card-text>
        </v-card>
        <v-card height="88px" flat tile class="secondary d-flex align-center">
          <v-card-text
            class="
              footer-font-style
              right-border-style
              white--text
              text-left
              pt-0
              pb-0
            "
          >
            <a :href="yojyo_url"
              >養生についての情報発信サイト<br />
              yojyo1192.com</a
            >
          </v-card-text>
        </v-card>
        <v-card height="88px" flat tile class="secondary d-flex align-center">
          <v-card-text class="footer-font-style white--text text-left d-flex">
            <a :href="therapist_url"
              >ユーザーとセラピストをつなぐ総合検索サイト<br />
              therapist-search.com</a
            >
          </v-card-text>
        </v-card>
        <v-card height="88px" class="secondary d-flex align-center" flat tile>
          <v-btn :href="facebook_url" icon class="mr-9">
            <v-img
              src="../../assets/f_logo_RGB-White_58.png"
              alt="Facebook-logo"
              contain
              width="28"
              height="28"
            />
          </v-btn>
        </v-card>
      </v-footer>
    </div>
    <!-- SP版 -->
    <div class="hidden-md-and-up">
      <v-footer padless class="secondary d-flex justify-center">
        <div class="d-flex flex-column">
          <div class="d-flex justify-center align-center">
            <div class="d-flex flex-column">
              <v-card flat tile class="secondary d-flex align-center">
                <v-card-text
                  class="
                    footer-font-style
                    bottom-border-style
                    white--text
                    pl-0
                    pr-0
                  "
                >
                  <a :href="ghc_url"
                    >Good Health Communicationsの情報発信サイト<br />
                    good-health-comms.jp</a
                  >
                </v-card-text>
              </v-card>
              <v-card flat tile class="secondary d-flex align-center">
                <v-card-text
                  class="
                    footer-font-style
                    bottom-border-style
                    white--text
                    pl-0
                    pr-0
                  "
                >
                  <a :href="yojyo_url"
                    >養生についての情報発信サイト<br />
                    yojyo1192.com</a
                  >
                </v-card-text>
              </v-card>
              <v-card flat tile class="secondary d-flex align-center">
                <v-card-text
                  class="footer-font-style white--text text-left pl-0 pr-0"
                >
                  <a :href="therapist_url"
                    >ユーザーとセラピストをつなぐ総合検索サイト<br />
                    therapist-search.com</a
                  >
                </v-card-text>
              </v-card>
            </div>
            <v-card class="secondary d-flex align-center ml-4" flat tile>
              <v-btn :href="facebook_url" icon>
                <v-img
                  src="../../assets/f_logo_RGB-White_58.png"
                  alt="Facebook-logo"
                  contain
                  width="28"
                  height="28"
                />
              </v-btn>
            </v-card>
          </div>
          <v-card class="secondary text-center" flat tile>
            <v-card-text class="footer-font-style white--text pb-1">
            </v-card-text>
          </v-card>
        </div>
      </v-footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",

  components: {},

  data: () => ({
    facebook_url: "https://www.facebook.com/yojyo1192",
    yojyo_url: "https://www.yojyo1192.com/",
    therapist_url: "https://www.therapist-search.com/",
    ghc_url: "https://good-health-comms.jp/",
  }),
};
</script>

<style scoped>
.right-border-style {
  border-right: 1px solid white;
}
.bottom-border-style {
  border-bottom: 1px solid white;
}
.footer-font-style {
  font-size: 10px;
  line-height: 1.5;
}
</style>
