<template>
  <v-app>
    <Header @send="toScroll" />
    <v-main>
      <FirstView ref="firstView" />
      <Philosophy ref="philosophy" />
      <Service ref="service" />
      <Message ref="message" />
      <Overview ref="overview" />
      <Contact ref="contact" />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Header from "../organisms/Header.vue";
import FirstView from "../organisms/FirstView.vue";
import Philosophy from "../organisms/Philosophy.vue";
import Service from "../organisms/Service.vue";
import Message from "../organisms/Message.vue";
import Overview from "../organisms/Overview.vue";
import Contact from "../organisms/Contact.vue";
import Footer from "../organisms/Footer.vue";

export default {
  name: "HomeTemplate",

  components: {
    Header,
    FirstView,
    Philosophy,
    Service,
    Message,
    Overview,
    Contact,
    Footer,
  },
  data() {
    return {
      offset: -400,
      scrollPoint: "firstView",
    };
  },

  computed: {
    options() {
      return {
        offset: this.offset,
      };
    },
  },

  methods: {
    toTop() {
      this.$vuetify.goTo(0);
    },
    toScroll(value) {
      this.scrollPoint = value;
      switch (this.scrollPoint) {
        case "philosophy":
          this.$vuetify.goTo(this.$refs.philosophy);
          break;
        case "service":
          this.$vuetify.goTo(this.$refs.service);
          break;
        case "message":
          this.$vuetify.goTo(this.$refs.message, this.options);
          break;
        case "sp_message":
          this.$vuetify.goTo(this.$refs.message);
          break;
        case "overview":
          this.$vuetify.goTo(this.$refs.overview);
          break;
        case "contact":
          this.$vuetify.goTo(this.$refs.contact);
          break;
      }
    },
  },
};
</script>
