<template>
  <HomeTemplate />
</template>

<script>
import HomeTemplate from "../templates/HomeTemplate.vue";

export default {
  name: "HomePage",
  components: {
    HomeTemplate,
  },
  data: () => ({
    //
  }),
};
</script>
