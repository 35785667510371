<template>
  <div>
    <div class="charColor--text text-left">
      <!-- PC版 -->
      <!-- フレックス -->
      <div class="d-flex">
        <!-- ロゴ画像 -->
        <img
          class="hidden-sm-and-down ml-10 mr-16"
          src="../../assets/ghc_logo_02-5.png"
          width="108px"
          height="108px"
        />
        <!-- タイトル１ -->
        <div class="hidden-sm-and-down sticky-note-pc-padding">
          <p class="text-h6">
            地域に根付いた環境に優しい健康コミュニティの実現に向けて
          </p>
          <!-- タイトル２ -->
          <p class="headline mb-4">Good Health Communications</p>
          <!-- 本文１ -->
          <p class="font-style mb-3">
            健康的な自立が叫ばれ久しくなります。<br />
            しかし、自分の健康を医療にゆだねている人も少なくありません。病気になって初めて健康の大切さを知り、そこからケアをするという考え方では、健康を維持するのに限界があります。日々の生活が健康に大きく関わっているのは周知の事実であることを考えると、これからの時代は医療機関の力だけを頼るのではなく、自分自身で身体を守っていく健康的自立が必要不可欠です。そのため、自分が住む地域の資源を最大限に活用しながら、自分の健康情報は自分、そして地域全体で管理していく意識が大切です。<br />
            そこで、我々は利用者本人と医療機関、さらには地域のコミュニティが健康のためにスムースに連携できるコミュニケーションプラットフォームGood
            Health Communications（GHCs）を提供しています。
          </p>

          <!-- good-health-comms.jpへの詳細リンク -->
          <v-row align="center" justify="end" class="mr-4">
            <v-btn text color="charColor" :href="ghc_url" class="mb-12">
              <div class="moreLink">
                <div class="moreLinkText">
                  詳細はこちら
                  <div class="lineArrow"></div>
                </div>
              </div>
            </v-btn>
          </v-row>
        </div>
      </div>

      <!-- SP版 -->
      <!-- コンポーネント枠 -->
      <div class="hidden-md-and-up text-center ma-2">
        <p class="text-h6 mb-16">
          地域に根付いた環境に優しい健康コミュニティの実現に向けて
        </p>
        <img
          src="../../assets/ghc_logo_02-5.png"
          width="108px"
          height="108px"
          class="mb-2"
        />
        <p class="headline mb-8 d-flex justify-center align-center text-center">
          Good Health Communications
        </p>
        <p class="font-style text-left">
          健康的な自立が叫ばれ久しくなります。<br />
          しかし、自分の健康を医療にゆだねている人も少なくありません。病気になって初めて健康の大切さを知り、そこからケアをするという考え方では、健康を維持するのに限界があります。日々の生活が健康に大きく関わっているのは周知の事実であることを考えると、これからの時代は医療機関の力だけを頼るのではなく、自分自身で身体を守っていく健康的自立が必要不可欠です。そのため、自分が住む地域の資源を最大限に活用しながら、自分の健康情報は自分、そして地域全体で管理していく意識が大切です。<br />
          そこで、我々は利用者本人と医療機関、さらには地域のコミュニティが健康のためにスムースに連携できるコミュニケーションプラットフォームGood
          Health Communications（GHCs）を提供しています。
        </p>

        <!-- good-health-comms.jpへの詳細リンク -->
        <v-row align="center" justify="end" class="mr-6">
          <v-btn text color="charColor" :href="ghc_url" class="mb-12">
            <div class="moreLink">
              <div class="moreLinkText">
                詳細はこちら
                <div class="lineArrow"></div>
              </div>
            </div>
          </v-btn>
        </v-row>

        <StickyNote
          title="COMO"
          sub="治療院向けアプリ"
          description="COMO（コモ）は、日々の体調や生活での問題点など、利用者本人が感じている健康イメージや意思を可視化するために開発された新感覚の治療院専用アプリ（コミュニケーションメモ）です。治療の際に患者さんの体調をCOMOに反映できるだけでなく、その情報から診察や診断、適切な対処法などの情報もサポートもしてくれます。またYOMOGIと組み合わせることで、日々の体調を管理することもできます。 なお、保存機能やセキュリティ管理も最新のシステムを導入していることから、鍼灸院や整骨院、さらにはヨガやアロマ教室などの統合医療従事者、スポーツジムや介護施設、健康関連グッズを販売するショップのカルテとしてもご利用することが可能です。"
          class="sticky-note-sp-padding"
        />
        <StickyNote
          title="YOMOGI⁺"
          sub="患者向けアプリ"
          description="YOMOGI⁺（ヨモギプラス）は、身体の状態を自分自身で把握・管理できるアプリで、「YOMOGI」の後継機種です。日々の体調や食事、歩行数などの情報を入力することで、病気と健康の中間である未病（みびょう）状態を可視化します。また、算出されたスコアやタイプに応じて環境に配慮した健康法を指導したり、さらには医療機関への受診の有無などもアドバイスしてくれます。自分の体調を見える化することで、自分では気が付かない小さな身体の変化を見つけ、予防することができることから、日ごろの体調管理として有効です。"
          class="sticky-note-sp-padding"
        />
      </div>
    </div>
  </div>
</template>

<script>
import StickyNote from "./StickyNote.vue";
export default {
  name: "ServiceBody",
  components: {
    StickyNote,
  },

  data: () => ({
    ghc_url: "https://good-health-comms.jp/",
  }),
};
</script>

<style scoped>
.font-style {
  font-size: 14px;
  line-height: 1.7;
}
.sticky-note-pc-padding {
  margin-bottom: 180px;
}
.sticky-note-sp-padding {
  margin-top: 90px;
}
.service-bg {
  background-color: #edf3e9;
}

/* ボタンの下線矢印 */
.moreLinkText {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
/*ホバーしたときに下線矢印も同時に色を変える*/
.moreLinkText:hover .lineArrow {
  border-bottom: 1px solid #41a849;
  border-right: 1px solid #41a849;
}
.moreLinkText:hover {
  color: #41a849;
}
.lineArrow {
  width: 140%;
  height: 15px;
  border-bottom: 1px solid #808077;
  border-right: 1px solid #808077;
  /*傾きを調節*/
  transform: skew(45deg);
  /*下線矢印をテキストに合わせてうまい具合にはめる*/
  margin: -10px 0 0 -16px;
}
</style>
