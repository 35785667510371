<template>
  <div class="hidden-sm-and-down">
    <v-btn-toggle tile color="#569F91" group class="text-center">
      <v-btn class="mt-1" width="79px" height="70px" @click="sendPhilosophy">
        <span class="caption">PHILOSOPHY</span>
      </v-btn>

      <v-btn class="mt-1" width="79px" height="70px" @click="sendService">
        <span class="caption">SERVICE</span>
      </v-btn>

      <v-btn class="mt-1" width="79px" height="70px" @click="sendMessage">
        <span class="caption">MESSAGE</span>
      </v-btn>

      <v-btn class="mt-1" width="79px" height="70px" @click="sendOverview">
        <span class="caption">OVERVIEW</span>
      </v-btn>

      <v-btn class="mt-1" width="79px" height="70px" @click="sendContact">
        <span class="caption">CONTACT</span>
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
export default {
  name: "TitleButtons",
  methods: {
    toTop() {
      this.$vuetify.goTo(0);
    },
    sendPhilosophy() {
      this.$emit("send", "philosophy");
    },
    sendService() {
      this.$emit("send", "service");
    },
    sendMessage() {
      this.$emit("send", "message");
    },
    sendOverview() {
      this.$emit("send", "overview");
    },
    sendContact() {
      this.$emit("send", "contact");
    },
  },
};
</script>

<style scoped></style>
