<template>
  <v-list>
    <v-list-item-group>
      <v-list-item class="fixed_1 text-center" @click="sendPhilosophy">
        <v-list-item-title class="border_style pt-3 pb-3"
          ><p class="charColor--text">PHILOSOPHY</p></v-list-item-title
        >
      </v-list-item>

      <v-list-item class="fixed_2 text-center" @click="sendService">
        <v-list-item-title class="border_style pt-3 pb-3"
          ><p class="charColor--text">SERVICE</p></v-list-item-title
        >
      </v-list-item>

      <v-list-item class="fixed_3 text-center" @click="sendMessage">
        <v-list-item-title class="border_style pt-3 pb-3"
          ><p class="charColor--text">MESSAGE</p></v-list-item-title
        >
      </v-list-item>

      <v-list-item class="fixed_4 text-center" @click="sendOverview">
        <v-list-item-title class="border_style pt-3 pb-3"
          ><p class="charColor--text">OVERVIEW</p></v-list-item-title
        >
      </v-list-item>

      <v-list-item class="fixed_5 text-center" @click="sendContact">
        <v-list-item-title
          ><p class="white-color-style ma-0">CONTACT</p></v-list-item-title
        >
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: "TitleLists",
  methods: {
    toTop() {
      this.$vuetify.goTo(0);
    },
    sendPhilosophy() {
      this.$emit("send", "philosophy");
    },
    sendService() {
      this.$emit("send", "service");
    },
    sendMessage() {
      this.$emit("send", "sp_message");
    },
    sendOverview() {
      this.$emit("send", "overview");
    },
    sendContact() {
      this.$emit("send", "contact");
    },
  },
};
</script>

<style scoped>
.fixed_1 {
  position: fixed;
  /*基準を画面の左上に*/
  top: 70px;
  left: 0px;
  /*余白が入らないように*/
  margin: 0px;
  /*以下装飾*/
  padding-inline: 138px;
  width: 100%;
  background-color: #edf3e9;
  border: none;
}

.fixed_2 {
  position: fixed;
  /*基準を画面の左上に*/
  top: 118px;
  left: 0px;
  /*余白が入らないように*/
  margin: 0px;
  /*以下装飾*/
  padding-inline: 138px;
  width: 100%;
  background-color: #edf3e9;
}

.fixed_3 {
  position: fixed;
  /*基準を画面の左上に*/
  top: 166px;
  left: 0px;
  /*余白が入らないように*/
  margin: 0px;
  /*以下装飾*/
  padding-inline: 138px;
  width: 100%;
  background-color: #edf3e9;
}

.fixed_4 {
  position: fixed;
  /*基準を画面の左上に*/
  top: 214px;
  left: 0px;
  /*余白が入らないように*/
  margin: 0px;
  /*以下装飾*/
  padding-inline: 138px;
  width: 100%;
  background-color: #edf3e9;
}

.fixed_5 {
  position: fixed;
  /*基準を画面の左上に*/
  top: 262px;
  left: 0px;
  /*余白が入らないように*/
  margin: 0px;
  /*以下装飾*/
  padding-inline: 138px;
  width: 100%;
  background-color: #569f91;
}

.border_style {
  border-bottom: 2px solid #dcdcd2;
  z-index: 1;
  width: 10px !important;
}

.white-color-style {
  color: white;
}
</style>