<template>
  <FirstViewText />
</template>

<script>
import FirstViewText from "../molecules/FirstViewText.vue";

export default {
  name: "FirstView",
  components: {
    FirstViewText,
  },
  data: () => ({
    //
  }),
};
</script>

<style scoped></style>
