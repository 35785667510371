import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

// export default new Vuetify({});
const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#EDF3E9",
        secondary: "#569F91",
        accent: "#EFE2A9",
        charColor: "#808077",
      },
    },
  },
});
export default vuetify;
