<template>
  <section class="home-hero">
    <!-- PC版 -->
    <v-container
      fluid
      fill-height
      class="home-hero__content hidden-sm-and-down"
    >
      <v-row>
        <v-col cols="2"></v-col>
        <v-col cols="6">
          <TitleWithSubTitle title="OVERVIEW" sub="会社概要" class="mt-12" />
          <div class="charColor--text">
            <v-container class="pt-14">
              <v-row class="info-row">
                <v-col cols="3">会社名</v-col>
                <v-col>YOJYOnet株式会社</v-col>
              </v-row>
              <v-row class="info-row">
                <v-col cols="3">代表者</v-col>
                <v-col>伊藤和憲</v-col>
              </v-row>
              <v-row class="info-row">
                <v-col cols="3">設立年月日</v-col>
                <v-col>2021年3月16日</v-col>
              </v-row>
              <v-row class="info-row">
                <v-col cols="3">所在地</v-col>
                <v-col
                  >京都府南丹市日吉町保野田ヒノ谷6-1明治国際医療大学内</v-col
                >
              </v-row>
              <v-row class="info-row">
                <v-col cols="3">電話</v-col>
                <v-col>0771-72-1231</v-col>
              </v-row>
              <v-row class="info-row">
                <v-col cols="3">FAX</v-col>
                <v-col>0771-72-1040</v-col>
              </v-row>
              <v-row class="info-row">
                <v-col cols="3">事業内容</v-col>
                <v-col
                  >健康アプリの開発と販売、アプリに関連した動画やテキストの開発と販売、健康情報に関する情報銀行としての企業・自治体へのコンサルタント、鍼灸治療院などの紹介、前各号に付帯する一切の業務</v-col
                >
              </v-row>
              <v-row class="info-row">
                <v-col cols="3">取引銀行</v-col>
                <v-col>京都銀行</v-col>
              </v-row>
              <v-row class="info-row">
                <v-col cols="3">資本金</v-col>
                <v-col>1000万円</v-col>
              </v-row>
              <v-row class="info-row">
                <v-col cols="3">従業員数</v-col>
                <v-col>5 名</v-col>
              </v-row>
              <v-row class="mb-10">
                <v-col cols="3">会社概要</v-col>
                <v-col
                  >明治国際医療大学発のベンチャー企業として、大学が長年培ってきた東洋医学の視点である養生（YOJYO)を用いた健康法で、地域貢献を目指す会社です</v-col
                >
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- SP版 -->
    <v-container fluid fill-height class="home-hero__content hidden-md-and-up">
      <v-row>
        <v-col cols="12"></v-col>
        <v-col cols="12">
          <TitleWithSubTitle
            title="OVERVIEW"
            sub="会社概要"
            class="text-center mt-6"
          />
          <div class="charColor--text">
            <v-container class="pt-14">
              <v-row class="info-row">
                <v-col cols="4">会社名</v-col>
                <v-col>YOJYOnet株式会社</v-col>
              </v-row>
              <v-row class="info-row">
                <v-col cols="4">代表者</v-col>
                <v-col>伊藤和憲</v-col>
              </v-row>
              <v-row class="info-row">
                <v-col cols="4">設立年月日</v-col>
                <v-col>2021年3月16日</v-col>
              </v-row>
              <v-row class="info-row">
                <v-col cols="4">所在地</v-col>
                <v-col
                  >京都府南丹市日吉町保野田ヒノ谷6-1明治国際医療大学内</v-col
                >
              </v-row>
              <v-row class="info-row">
                <v-col cols="4">電話</v-col>
                <v-col>0771-72-1231</v-col>
              </v-row>
              <v-row class="info-row">
                <v-col cols="4">FAX</v-col>
                <v-col>0771-72-1040</v-col>
              </v-row>
              <v-row class="info-row">
                <v-col cols="4">事業内容</v-col>
                <v-col
                  >健康アプリの開発と販売、アプリに関連した動画やテキストの開発と販売、健康情報に関する情報銀行としての企業・自治体へのコンサルタント、鍼灸治療院などの紹介、前各号に付帯する一切の業務</v-col
                >
              </v-row>
              <v-row class="info-row">
                <v-col cols="4">取引銀行</v-col>
                <v-col>京都銀行</v-col>
              </v-row>
              <v-row class="info-row">
                <v-col cols="4">資本金</v-col>
                <v-col>1000万円</v-col>
              </v-row>
              <v-row class="info-row">
                <v-col cols="4">従業員数</v-col>
                <v-col>5 名</v-col>
              </v-row>
              <v-row>
                <v-col cols="4">会社概要</v-col>
                <v-col
                  >明治国際医療大学発のベンチャー企業として、大学が長年培ってきた東洋医学の視点である養生（YOJYO)を用いた健康法で、地域貢献を目指す会社です</v-col
                >
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import TitleWithSubTitle from "../molecules/TitleWithSubTitle.vue";
export default {
  name: "Overview",
  components: {
    TitleWithSubTitle,
  },
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
.home-hero__content {
  background: url("../../assets/bg_02-5.jpg");
  background-size: cover;
  background-position: center center;
  width: 100%;

  &-text {
    color: white;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
  }
}

.info-row {
  border-bottom: solid;
  border-bottom-color: #dcdcd2;
  border-width: 1px;
}
</style>
