<template>
  <!-- 背景 -->
  <div class="service-bg">
    <!-- 上パディング14 -->
    <v-container class="pt-14">
      <v-row class="text-center">
        <v-col>
          <!-- PC版 -->
          <TitleWithSubTitle
            title="SERVICE"
            sub="事業内容"
            class="hidden-sm-and-down mb-14"
          />
          <!-- SP版 -->
          <TitleWithSubTitle
            title="SERVICE"
            sub="事業内容"
            class="hidden-md-and-up"
          />
          <ServiceBody class="hidden-sm-and-down ml-16 mr-16 pl-16 pr-16" />
          <ServiceBody class="hidden-md-and-up" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TitleWithSubTitle from "../molecules/TitleWithSubTitle.vue";
import ServiceBody from "../molecules/ServiceBody.vue";
export default {
  name: "Service",
  components: {
    TitleWithSubTitle,
    ServiceBody,
  },
};
</script>

<style scoped>
.service-bg {
  background-color: #edf3e9;
}
</style>
