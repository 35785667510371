<template>
  <!-- 全体のクラス -->
  <div class="d-flex flex-column">
    <!-- 共通：ヘッダー用 -->
    <v-app-bar app flat color="rgb(255, 255, 255, 0.75)" height="70">
      <!-- 左側ロゴ -->
      <LogoButton />

      <!-- flexboxによる成り行き設定 -->
      <div class="flex-grow-1"></div>

      <!-- PC右側タイトル群 -->
      <TitleButtons @send="toScroll" />

      <!-- SPハンバーガー -->
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <div v-show="drawer" class="fixed">
        <TitleLists @send="toScroll" />
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import LogoButton from "../atoms/LogoButton.vue";
import TitleButtons from "../atoms/TitleButtons.vue";
import TitleLists from "../atoms/TitleLists.vue";

export default {
  name: "Header",
  components: {
    LogoButton,
    TitleButtons,
    TitleLists,
  },
  props: {},
  data: () => ({
    scrollPoint: "",
    drawer: false,
  }),
  methods: {
    toScroll(value) {
      this.scrollPoint = value;
      switch (this.scrollPoint) {
        case "philosophy":
          this.$emit("send", "philosophy");
          this.drawer = false;
          break;
        case "service":
          this.$emit("send", "service");
          this.drawer = false;
          break;
        case "message":
          this.$emit("send", "message");
          this.drawer = false;
          break;
        case "sp_message":
          this.$emit("send", "sp_message");
          this.drawer = false;
          break;
        case "overview":
          this.$emit("send", "overview");
          this.drawer = false;
          break;
        case "contact":
          this.$emit("send", "contact");
          this.drawer = false;
          break;
      }
    },
  },
};
</script>

<style scoped></style>
