<template>
  <div>
    <!-- PC版 -->
    <div class="hidden-sm-and-down">
      <div
        class="
          mission-description-style
          charColor--text
          mb-6
          text-left
          d-flex
          justify-center
          align-center
        "
      >
        <img class="mr-5 mb-8" src="../../assets/01.svg" width="52px" />
        <div>
          <p class="mission-title-style mb-1">情報革命</p>
          ICTを活用した健康管理システムの構築とインフラ整備
        </div>
      </div>
      <div
        class="
          mission-description-style
          charColor--text
          mb-6
          text-left
          d-flex
          justify-center
          align-center
        "
      >
        <img class="mr-5 mb-8" src="../../assets/02.svg" width="52px" />
        <div>
          <p class="mission-title-style mb-1">健康革命</p>
          地域資源を活用した健康生活の提案と実現
        </div>
      </div>
      <div
        class="
          mission-description-style
          charColor--text
          mb-6
          text-left
          d-flex
          justify-center
          align-center
        "
      >
        <img class="mr-5 mb-8" src="../../assets/03.svg" width="52px" />
        <div>
          <p class="mission-title-style mb-1">デジタル革命</p>
          オンラインとオフラインが共存した新しい健康生活
        </div>
      </div>
      <div
        class="
          mission-description-style
          charColor--text
          mb-6
          text-left
          d-flex
          justify-center
          align-center
        "
      >
        <img class="mr-5 mb-8" src="../../assets/04.svg" width="52px" />
        <div>
          <p class="mission-title-style mb-1">行政革命</p>
          エビデンスに基づく健康管理とまちづくり
        </div>
      </div>
    </div>

    <!-- SP版 -->
    <div class="charColor--text text-h6 d-inline-flex flex-column ma-3">
      <div class="hidden-md-and-up">
        <p
          class="text-h6 charColor--text mb-6 d-flex justify-start align-center"
        >
          <img class="mr-5" src="../../assets/01.svg" width="52px" />
          情報革命
        </p>
        <p class="mb-6 d-flex justify-start align-center text-left">
          ICTを活用した健康管理システムの構築とインフラ整備
        </p>
      </div>
      <div class="hidden-md-and-up">
        <p
          class="text-h6 charColor--text mb-6 d-flex justify-start align-center"
        >
          <img class="mr-5" src="../../assets/02.svg" width="52px" />
          健康革命
        </p>
        <p class="mb-6 d-flex justify-start align-center text-left">
          地域資源を活用した健康生活の提案と実現
        </p>
      </div>
      <div class="hidden-md-and-up">
        <p
          class="text-h6 charColor--text mb-6 d-flex justify-start align-center"
        >
          <img class="mr-5" src="../../assets/03.svg" width="52px" />
          デジタル革命
        </p>
        <p class="mb-6 d-flex justify-start align-center text-left">
          オンラインとオフラインが共存した新しい健康生活
        </p>
      </div>
      <div class="hidden-md-and-up">
        <p
          class="text-h6 charColor--text mb-6 d-flex justify-start align-center"
        >
          <img class="mr-5" src="../../assets/04.svg" width="52px" />
          行政革命
        </p>
        <p class="mb-6 d-flex justify-start align-center text-left">
          エビデンスに基づく健康管理とまちづくり
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MissionItems",
};
</script>

<style scoped>
.mission-title-style {
  font-size: 19.2px;
}
.mission-description-style {
  font-size: 16px;
}
</style>
