<template>
  <div>
    <!-- PC版 -->
    <!-- SM削除、色 -->
    <div class="hidden-sm-and-down secondary--text">
      <!-- フォントスタイル、フォント間隔 -->
      <p class="futura-font letter-spacing-style mb-0">
        {{ title }}
      </p>
      <!-- フォントスタイル、フォント間隔 -->
      <p class="tbchibi-font letter-spacing-style">
        {{ sub }}
      </p>
    </div>
    <!-- SP版 -->
    <!-- MD削除、色 -->
    <div class="hidden-md-and-up secondary--text">
      <!-- フォントスタイル、フォント間隔、下マージン0 -->
      <p class="futura-font letter-spacing-style mb-0">
        {{ title }}
      </p>
      <!-- フォントスタイル、フォント間隔 -->
      <p class="tbchibi-font letter-spacing-style">
        {{ sub }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TitleWithSubTitle",
  props: {
    title: {
      type: String,
      required: true,
    },
    sub: {
      type: String,
      required: true,
    },
  },
};
</script>

// 共通のスタイル
<style>
.futura-font {
  font-size: 40px;
  font-family: futura-pt, sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
}

.tbchibi-font {
  font-size: 14px;
  font-family: tbchibirgothicplusk-pro, sans-serif !important;
}
</style>

// 個別のスタイル
<style scoped>
.letter-spacing-style {
  letter-spacing: 0.05em;
}
</style>
