<template>
  <v-card class="atamadasi-memo">
    <img
      class="atamadasi-icon"
      src="../../assets/karte_circle.png"
      width="100px"
    />
    <div class="charColor--text">
      <!-- PC版 -->
      <div class="hidden-sm-and-down text-h5 mb-2">
        {{ title }}
      </div>
      <p class="hidden-sm-and-down text-body-2">{{ sub }}</p>
      <p class="hidden-sm-and-down font-style pa-9 pt-0 text-left">
        {{ description }}
      </p>

      <!-- SP版 -->
      <div class="hidden-md-and-up text-h5">
        {{ title }}
      </div>
      <p class="hidden-md-and-up text-body-2">{{ sub }}</p>
      <p class="hidden-md-and-up font-style pa-9 text-left">
        {{ description }}
      </p>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "StickyNote",
  props: {
    title: {
      type: String,
      required: true,
    },
    sub: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
</script>

// 共通のスタイル
<style>
.atamadasi-icon {
  margin-top: -50px;
}
.atamadasi-memo {
  margin-top: -140px;
}
</style>

// 個別のスタイル
<style scoped>
.font-style {
  font-size: 14px;
  line-height: 1.7;
}
</style>
