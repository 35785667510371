import firebase from "firebase";

const config = {
  apiKey: "AIzaSyCSi1tMrTTvrZTVd3ZsfsP3JKmlxd23lw8",
  authDomain: "send-contact-mail.firebaseapp.com",
  databaseURL: "https://send-contact-mail-default-rtdb.firebaseio.com",
  projectId: "send-contact-mail",
  storageBucket: "send-contact-mail.appspot.com",
  messagingSenderId: "44517615464",
  appId: "1:44517615464:web:2567b0d47880c7d04c98dd",
  measurementId: "G-RB69N0ZMZD",
};
firebase.initializeApp(config);
export const functions = firebase.functions();
