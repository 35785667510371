<template>
  <section>
    <!-- PC版 -->
    <v-container fluid fill-height class="hidden-sm-and-down first-view-pc">
      <v-row>
        <v-col class="first-view-pc-text">
          <div class="text-shadow-style d-flex justify-center">
            <p class="ma-0">
              健康になる力を、<br />
              社会を変える力にイノベーションする
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- SP版 -->
    <v-container fluid fill-height class="hidden-md-and-up first-view-sp">
      <v-row>
        <v-col class="first-view-sp-text">
          <div class="text-shadow-style d-flex justify-center">
            <p class="ma-0">
              健康になる力を、<br />
              社会を変える力に<br />
              イノベーションする
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "FirstViewText",
  data: () => ({
    //
  }),
};
</script>

<style lang="scss" scoped>
.first-view-pc {
  background: url("../../assets/bg_01-2.jpg");
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 50vh;

  &-text {
    color: white;
    text-align: left;
    font-size: 40px;
    font-weight: bold;

    font-family: tbchibirgothicplusk-pro, sans-serif;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.2em;
  }
}
.first-view-sp {
  background: url("../../assets/bg_01-2.jpg");
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 50vh;

  &-text {
    color: white;
    text-align: left;
    font-size: 28px;
    font-weight: bold;

    font-family: tbchibirgothicplusk-pro, sans-serif;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.2em;
    line-height: 1.6;
  }
}

.text-shadow-style {
  text-shadow: 0px 3px 6px #00000029;
}
</style>


