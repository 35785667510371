<template>
  <!-- PC版 -->
  <div>
    <div class="charColor--text hidden-sm-and-down">
      <p class="font-weight-bold mb-1">YOJYOnet株式会社　CEO　伊藤和憲</p>
      <p class="text-body-2 mb-1">明治国際医療大学　鍼灸学部　学部長・教授</p>
      <p class="text-body-2 mb-1">
        明治国際医療大学　大学院鍼灸学研究科　研究科長・教授
      </p>
      <p class="text-body-2 mb-14">明治国際医療大学　鍼灸臨床部長</p>
    </div>

    <!-- SP版 -->
    <div
      class="
        charColor--text
        hidden-md-and-up
        ma-3
        d-flex
        flex-column
        justify-start
      "
    >
      <p class="font-weight-bold mb-1 hidden-md-and-up">
        YOJYOnet株式会社　CEO　伊藤和憲
      </p>
      <p class="text-body-2 mb-1 hidden-md-and-up">
        明治国際医療大学　鍼灸学部　学部長・教授
      </p>
      <p class="text-body-2 mb-1 hidden-md-and-up">
        明治国際医療大学　大学院鍼灸学研究科　研究科長・教授
      </p>
      <p class="text-body-2 mb-5 hidden-md-and-up">
        明治国際医療大学　鍼灸臨床部長
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Profile",
};
</script>

<style scoped></style>